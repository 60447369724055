import React, { useCallback, useRef, useState,useEffect } from "react";
import Webcam from "react-webcam";
import '../WebcamVideo.css';
import logo from '../imgs/logo.png';
import inst_video from '../video/inst_video.webm';
import Vitals from "./Vitals";
import {useLocation} from 'react-router-dom';

let timerValue = 10;
let prodURL = 'https://vai.docsun.health/api/videosdk';
let latestIntervalID = null;
let uploadID = ''
let uploadStatus = false;
let hr_rate = 0;
let spo2 = 0;
let br_rate = 0;
let bp_rate = 0;
let bs_rate = 0;
let chol_rate = 0;
let status = 0;
let defaultMessage = 'Click on Start Capture to start recording video'

export default function WebcamVideo() {
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [capturing, setCapturing] = useState(false);
  let [recordedChunks, setRecordedChunks] = useState([]);
  // let [accessToken,setToken] = useState('');
  var [resultsGot,setResultsGot] = useState(false);
  var [infoMessage,setInfoMessage]=useState(defaultMessage);

  const [hideVideo,sethideVideo] =  useState(false);
    const [hideSkip,sethideSkip] = useState(false);
    const vidRef = useRef(null);
    const handleStopVideo = ()=>{
        vidRef.current.pause();
    }
  const location = useLocation();
  console.log('Location params : ',location)
    
  useEffect(() => {
    const loadLatestResult = async ()=>{
      // console.log('Upload status : ',uploadStatus)
      if (uploadStatus){
        setResultsGot(false);
        // console.log('In condition true :',uploadStatus)
        let headers = {
          // 'Authorization':`Bearer ${localStorage.getItem("token")}`
          'Authorization':`Bearer ${location.state.token}`
        }
        // console.log('header : ',headers);
        // console.log('URL : ',`${prodURL}/diagnosis/latest?id=${uploadID}`)
        let response = await fetch(`${prodURL}/diagnosis/latest?id=${uploadID}`,{
          method:'GET',
          headers:headers,
        })
        response = await response.json();
        console.log('Latest Diagnosis : ',response)
        uploadStatus=false;
        if(response.success){
          hr_rate=response.result.heart_rate
          spo2=response.result.spo2
          br_rate=response.result.respiration_rate
          bp_rate=response.result.blood_pressure_range
          bs_rate=response.result.blood_sugar_range
          chol_rate=response.result.cholestrol
          status=response.result.status
          setResultsGot(true);
          setInfoMessage(defaultMessage);
        }else{
          setInfoMessage("There was an error in video.\n Please click on Start Capture and do a new recording.");
          hr_rate=0;bp_rate=0;br_rate=0;spo2=0;bs_rate=0;chol_rate=0;
          setResultsGot(false);
        }
        // clearInterval(latestIntervalID)
      }
    }
    latestIntervalID = setInterval(()=>{
      loadLatestResult();
    },5000);
  },[])

  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const handleStartCaptureClick = useCallback(() => {
    setCapturing(true);
    // try {
    //   mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
    //     mimeType: "video/webm",
    //   });
    // } catch (e) {
    //   mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
    //     mimeType: "video/mp4",
    //   });
    // }
    if (MediaRecorder.isTypeSupported('video/webm; codecs=vp9')){
        var options = {mimeType: 'video/webm; codecs=vp9'};
    } else  if (MediaRecorder.isTypeSupported('video/webm')) {
        var options = {mimeType: 'video/webm'};
    } else if (MediaRecorder.isTypeSupported('video/mp4')) {
        var options = {mimeType: 'video/mp4', videoBitsPerSecond : 2500000};
    } else {
        console.error("no suitable mimetype found for this device");
    }
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream,options)
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start(1000);
    setInfoMessage('');
    const intervalID =setInterval(()=>{
      // console.log(timerValue)
      if (timerValue >0){
        timerValue = timerValue -1;
      }else{
        timerValue = 10;
      }
    },1000)
    setTimeout(()=>{
      mediaRecorderRef.current.stop();
      setCapturing(false);
      setInfoMessage('Video recording completed. Please click on Upload Video to get scan results.')
      clearInterval(intervalID);
      timerValue=10;
    },10000)
    
  }, [webcamRef, setCapturing, mediaRecorderRef, handleDataAvailable,setInfoMessage]);

  const handleDownload = useCallback( async () => {
    if (recordedChunks.length) {
      let formData = new FormData()
      let blob = new Blob(recordedChunks, {
        type: "video/mp4",
      });
      let headers = {
        // 'Authorization':`Bearer ${localStorage.getItem("token")}`
        'Authorization':`Bearer ${location.state.token}`
      }
      console.log('Video upload header : ',headers)
      let datestring = new Date()
      datestring = datestring.toString();
      const durl = URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = durl;
      a.download = `video-apidemo-upload.mp4`;
      a.click();
      window.URL.revokeObjectURL(durl);
      formData.append("video",blob,`video-apidemo-upload.mp4`)
      // formData.append("licence_key",localStorage.getItem("licence"))
      formData.append("licence_key",location.state.licence)
      formData.append("custom1","demo-api")
      for (const value of formData.values()) {
        console.log(value);
      }
      let response = await fetch(`${prodURL}/diagnosis/upload`,{
        method:'POST',
        headers:headers,
        body:formData
      })
      response = await response.json()
      console.log('Video upload Response : ',response);
      if (response.success){
        uploadID=response.result.id;
        uploadStatus= true;
        setInfoMessage('Loading scan results.')
      }
      setRecordedChunks([]);
    }
  }, [recordedChunks,setInfoMessage]);

  const videoConstraints = {
    // width: 420,
    // height: 420,
    facingMode: "user",
  };

  return (
    <div className="body">
      {/* <img src={logo} alt="Docsun" className="logo"/> */}
      <div className="webcam__container">
        <div className='skip__container'>
          {hideSkip ? (
            <button id='skip__btn' className="" onClick={()=>{sethideVideo(true);sethideSkip(true);handleStopVideo();}} hidden={hideSkip}>Skip Video</button>
          ): (
            <button id='skip__btn' className="skip__btn" onClick={()=>{sethideVideo(true);sethideSkip(true);handleStopVideo();}} hidden={hideSkip}>Skip Video</button>
          )}
        </div>
        <div className="inst__container">
          <video ref={vidRef} loop muted autoPlay controls hidden={hideVideo} className="inst__video" >
              <source src={inst_video} type="video/webm"></source>
          </video>
        </div>
        
        <Webcam
          className="webcam__video"
          // height={400}
          // width={400}
          audio={false}
          mirrored={true}
          ref={webcamRef}
          videoConstraints={videoConstraints}
        />
        <div className="timer__container">
          <input className="timer__value" value={timerValue}></input>
        </div>
        <div className="button__container">
          {capturing ? (
            <button onClick={handleStartCaptureClick} hidden className="">Start Capture</button>
          ) : (
            <button onClick={handleStartCaptureClick} className="start__button">Start Capture</button>
          )}
          {recordedChunks.length > 0 && (capturing == false) &&(
            <button onClick={handleDownload} className="upload__button">Upload Video</button>
          )}
        </div>
      </div>
      <div className="info__container">
        <p className="info__message"><span className="info__value">{infoMessage}</span></p>
      </div>
      {resultsGot ?(
        <Vitals hr={hr_rate} spo2={spo2} br={br_rate} bp={bp_rate} bs={bs_rate} chol={chol_rate} status={status}/>
      ) : (
        <Vitals hr={hr_rate} spo2={spo2} br={br_rate} bp={bp_rate} bs={bs_rate} chol={chol_rate} status={status}/>
      )}
    </div>
  );
}