import React from 'react'
import '../Vitals.css'
import StressChart from './StressChart'
export default function Vitals(props) {
  return (
    <div className='vitals__body'>
        <div className='scan__vitalBox'>
            <div className='scan__Box'>
                <div className='scanLabel__box'>
                    <label htmlFor='heart_rate' className='scan__label' >Heart Rate</label>
                </div>
                <div className='scanInput__box'>
                    <input type="text" readOnly id="heart_rate" value={props.hr} className='scan__input'></input>
                </div>
            </div>
            <div className='scan__Box'>
                <label htmlFor='resp_rate' className='scan__label'>Breathing Rate</label>
                <input type="text" readOnly id="resp_rate" className='scan__input' value={props.br} ></input>
            </div>
            <div className='scan__Box'>
                <label htmlFor='spo2' className='scan__label'>Spo2 Rate</label>
                <input type="text" readOnly id="spo2" className='scan__input' value={props.spo2}></input>
            </div>
        </div>
        <div className='scan__vitalBox'>
            <div className='scan__Box'>
                <label htmlFor='blood_pressure' className='scan__label'>Blood Pressure</label>
                <input type="text" readOnly id="blood_pressure" className='scan__input' value={props.bp}></input>
            </div>
            <div className='scan__Box'>
                <label htmlFor='bs_rate' className='scan__label'>Blood Sugar</label>
                <input type="text" readOnly id="bs_rate" className='scan__input' value={props.bs}></input>
            </div>
            <div className='scan__Box'>
                <label htmlFor='chol_rate' className='scan__label'>Blood Cholesterol</label>
                <input type="text" readOnly id="chol_rate" className='scan__input' value={props.chol}></input>
            </div>
        </div>
        <div className='divider__container'>
            <hr className='divider__style'></hr>
        </div>
        {/* <StressChart/> */}
    </div>
  )
}
