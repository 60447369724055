import React from 'react'
import { useLocation } from 'react-router-dom'
import WebcamVideo from './WebcamVideo'
export default function Scan() {
  const location = useLocation();
  console.log('state : ',location.state)
  return (
    <div className='scan'>
        <WebcamVideo/>
    </div>
  )
}
